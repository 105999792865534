import { apiRequests, mapApiRequests } from '../utilities/axios-factory'
import store from '../store'

function getTransportationProfileGeolocationMetadata ({ transportationProfileId, shortcodeId }) {
  return mapApiRequests.post('/v1/geo-location/transportation-profile/metadata/', { transportationProfileId, shortcodeId }, {
    headers: {
      Authorization: store.getters.tokenId
    }
  }).then((result) => {
    return result.data
  })
}

function getRoutesForDateTime ({ transportationProfileId }) {
  return apiRequests.get(`/v1/transportation-profile/${transportationProfileId}/transportation-profile-routes/valid/`, {
    params: { dateTime: new Date().toISOString() },
    headers: {
      Authorization: store.getters.tokenId
    }
  }).then((result) => {
    return result.data
  })
}

async function getTransportationRoutes ({ transportationProfileId }) {
  const result = await apiRequests.get(`/v1/transportation-profile/${transportationProfileId}/transportation-profile-routes/detailed/`, {
    headers: {
      Authorization: store.getters.tokenId
    }
  })

  return result?.data
}

function getTransportationProfileRouteById ({ transportationProfileRouteId }) {
  return apiRequests.get(`/v1/transportation-profile-route/${transportationProfileRouteId}/`, {
    headers: {
      Authorization: store.getters.tokenId
    }
  }).then((result) => {
    return result.data
  })
}

function findTransportationProfiles ({ transportationProfileId, shortcodeId }) {
  return apiRequests.get('/v1/transportation-profile/', {
    params: { filter: { where: { id: transportationProfileId, shortcodeId: shortcodeId ? encodeURIComponent(shortcodeId) : undefined } } },
    headers: {
      Authorization: store.getters.tokenId
    }
  }).then((result) => {
    return result.data
  })
}

function getBusStopsForRoute (transportationProfileRouteId) {
  return apiRequests.get(`/v1/transportation-profile-route/${transportationProfileRouteId}/bus-stops/`, {
    headers: {
      Authorization: store.getters.tokenId
    }
  }).then((result) => {
    return result.data
  })
}

function getRouteFareByOriginAndDestination ({
  transportationProfileRouteId,
  startLongitude,
  startLatitude,
  endLongitude,
  endLatitude
}) {
  return apiRequests.get('/v1/route-fare/fare-by-origin-destination/', {
    params: {
      transportationProfileRouteId,
      startLongitude,
      startLatitude,
      endLongitude,
      endLatitude
    },
    headers: {
      Authorization: store.getters.tokenId
    }
  }).then((result) => {
    return result.data
  })
}

function purchaseOneTimeTicket ({
  agentProfileId,
  passengerProfileId,
  transportationProfileId,
  transportationProfileRouteId,
  startLongitude,
  startLatitude,
  endLongitude,
  endLatitude
}) {
  return apiRequests.post(`/v1/agent-profile/${agentProfileId}/passenger-one-time-ticket/`, {
    passengerProfileId,
    transportationProfileId,
    transportationProfileRouteId,
    startLongitude,
    startLatitude,
    endLongitude,
    endLatitude
  }, {
    headers: {
      Authorization: store.getters.tokenId,
      'x-agent-authorization': store.getters?.passengerAuthorizationToken?.tokenId
    }
  }).then((result) => {
    return result.data
  })
}

export {
  getTransportationProfileGeolocationMetadata,
  getRoutesForDateTime,
  findTransportationProfiles,
  getTransportationProfileRouteById,
  getBusStopsForRoute,
  getRouteFareByOriginAndDestination,
  purchaseOneTimeTicket,
  getTransportationRoutes
}
